@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts.css";
@import "tarteaucitron.css";
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

@layer base {
  body {
    @apply font-sans text-black;
  }
}

@layer components {
  .prose {
    @apply max-w-3xl 
      mx-auto
      prose-h2:text-3xl
      prose-h2:font-serif
      prose-h3:text-xl
      prose-h3:font-serif
      prose-h3:font-bold
      prose-h2:mb-8
      prose-h3:mb-8
      prose-blockquote:font-normal
      prose-blockquote:pl-8
      prose-blockquote:py-4
      prose-blockquote:mb-8
      prose-blockquote:text-black
      prose-blockquote:border-primary
      prose-a:underline-offset-4
      prose-p:mb-8;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.prose a {
  @apply hover:text-primary visited:text-primary-darker visited:hover:text-primary transition;
}

.prose a.button {
  @apply inline-block py-4 px-8 rounded-full border text-xs uppercase no-underline font-medium tracking-wider transition;
}

.prose a.button.button--primary:not(.inverse) {
  @apply border-primary bg-primary text-primary-darker hover:border-primary-light hover:bg-primary-dark;
}

.prose a.button.button--primary.inverse {
  @apply border-primary bg-transparent text-primary hover:bg-primary hover:text-primary-darker;
}

.prose a.button.button--secondary:not(.inverse) {
  @apply border-secondary bg-secondary text-white hover:bg-transparent hover:text-secondary;
}

.prose a.button.button--secondary.inverse {
  @apply border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white;
}

.prose p:first-child {
  margin-top: 0;
}

.prose p:last-child {
  margin-bottom: 0;
}

.prose-inverse {
  @apply text-white;
}

.prose-inverse a {
  @apply text-white underline underline-offset-4 decoration-primary hover:text-primary visited:text-primary-light visited:hover:text-primary transition-colors;
}

#mainMenuWrapper {
  @apply fixed left-0 top-0 inset-0 bg-secondary-dark md:relative md:bg-transparent z-40;
}

.menu-ul-0 {
  @apply flex md:flex-row flex-col md:items-center md:justify-center gap-8 md:text-center;
}

.menu-li-0 + .menu-li-0:before {
  @apply inline-block h-2.5 w-2.5 mr-8 border-2 border-primary rounded-full md:content-[''];
}

.menu-a-0 {
  @apply inline-block text-white hover:text-primary text-2xl md:text-lg transition-colors;
}

.menu-button-0 {
  @apply inline-flex gap-4 text-white hover:text-primary text-2xl md:text-lg transition-colors;
}

.menu-button-0[aria-expanded="true"] {
  @apply text-primary md:text-white;
}

.menu-ul-1 {
  @apply flex flex-col gap-2 mt-4 md:block md:mt-0 lg:columns-3 xl:columns-4;
}

.menu-li-1 {
}

.menu-a-1,
.menu-button-1 {
  @apply block py-1 md:py-2 bg-transparent font-medium transition-all hover:text-primary md:hover:text-secondary md:hover:text-opacity-50;
}

.menu-a-1[aria-current="page"] {
  @apply text-primary md:text-secondary md:text-opacity-50;
}

.menu-a-2 {
  @apply block text-white md:text-secondary py-1 before:content-['-'] before:text-primary before:mr-2 hover:text-primary md:hover:text-black;
}

.search-bar {
  @apply mt-6;
}

.affix-up .menu-ul-1 {
  @apply xl:mt-4;
}

.affix-down .menu-ul-1 {
  @apply xl:hidden;
}

.affix-up .search-bar {
  @apply mt-4;
}

.affix-down .search-bar {
  @apply hidden;
}

.swiper-button-next {
  @apply hidden;
}

.swiper-button-prev {
  @apply hidden;
}

#events .swiper {
  @apply overflow-visible;
}

html[lang="fr"] .locale-display:not(.fr) {
  display: none !important;
}

html[lang="en"] .locale-display:not(.en) {
  display: none !important;
}

html[lang="de"] .locale-display:not(.de) {
  display: none !important;
}

html[lang="nl"] .locale-display:not(.nl) {
  display: none !important;
}

.memoire-vive {
  @apply md:border-r md:border-white h-14 md:h-auto px-4;
}
