.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%;
}

#tarteaucitronContentWrapper {
  display: unset;
}

button.tarteaucitron-toggle-group {
  display: block;
}

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  margin-top: 7px;
}

.tarteaucitronLine {
  border-left: 0px solid transparent !important;
}
/*****/

/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
  }

  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    div#tarteaucitronMainLineOffset
    .tarteaucitronName {
    display: none !important;
  }

  #tarteaucitronServices_mandatory
    li.tarteaucitronLine
    .tarteaucitronName
    span {
    width: 100% !important;
    display: inline-block;
  }
  li.tarteaucitronLine .tarteaucitronName span {
    width: 80% !important;
    display: inline-block;
  }
  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0;
  }
  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    button.tarteaucitron-toggle-group:before {
    content: "\0025BE";
    font-weight: 700;
    font-size: 14px;
  }
  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    .tarteaucitronIsExpanded
    button.tarteaucitron-toggle-group:before {
    content: "\0025B4";
  }
}
@media screen and (min-width: 768px) {
  #tarteaucitron {
    top: 6% !important;
  }

  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    button.tarteaucitron-toggle-group:after {
    content: "\0025BE";
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px;
  }
  html
    body
    #tarteaucitronRoot
    #tarteaucitron
    .tarteaucitronBorder
    .tarteaucitronIsExpanded
    button.tarteaucitron-toggle-group:after {
    content: "\0025B4";
    margin-left: 15px;
  }
}

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms;
}

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article,
#tarteaucitronRoot aside,
#tarteaucitronRoot details,
#tarteaucitronRoot figcaption,
#tarteaucitronRoot figure,
#tarteaucitronRoot footer,
#tarteaucitronRoot header,
#tarteaucitronRoot hgroup,
#tarteaucitronRoot menu,
#tarteaucitronRoot nav,
#tarteaucitronRoot section {
  display: block;
}
#tarteaucitronRoot ol,
#tarteaucitronRoot ul {
  list-style: none;
}
#tarteaucitronRoot blockquote,
#tarteaucitronRoot q {
  quotes: none;
}
#tarteaucitronRoot blockquote:before,
#tarteaucitronRoot blockquote:after,
#tarteaucitronRoot q:before,
#tarteaucitronRoot q:after {
  content: "";
  content: none;
}
#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0;
}

#tarteaucitronRoot a:focus-visible,
#tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8;
}

/***
* Better scroll management
*/
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important;
}

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px;
}

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background: theme("colors.gray.900");
  outline: 0px solid slategrey;
}

div#tarteaucitronServices {
  @apply rounded-lg shadow-lg ring-1 ring-black ring-opacity-5;
}

/***
* Responsive layout for the control panel
*/
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important;
  }

  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px;
  }
}

@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer,
  #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important;
  }

  #tarteaucitronAlertSmall
    #tarteaucitronCookiesListContainer
    #tarteaucitronCookiesList {
    border: 0 !important;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important;
  }

  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%;
  }

  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important;
  }

  #tarteaucitron
    #tarteaucitronServices
    .tarteaucitronLine
    .tarteaucitronAsk
    button {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important;
  }
}

/***
* Common value
*/
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto;
}

#tarteaucitronRoot button#tarteaucitronBack {
  background: theme("colors.gray.500");
}

#tarteaucitron .clear {
  clear: both;
}

#tarteaucitronRoot button {
  background: transparent;
  border: 0;
}

#tarteaucitronAlertBig strong,
#tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a,
#tarteaucitronAlertSmall a {
  color: #fff;
}

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500;
}

#tarteaucitron ul {
  padding: 0;
}

#tarteaucitron .tarteaucitronH1,
#tarteaucitron .tarteaucitronH2,
#tarteaucitron .tarteaucitronH3,
#tarteaucitron .tarteaucitronH4,
#tarteaucitron .tarteaucitronH5,
#tarteaucitron .tarteaucitronH6 {
  display: block;
}

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
/***
* Root div added just before </body>
*/
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

#tarteaucitronRoot * {
  box-sizing: initial;
  color: theme("colors.gray.900");
  line-height: normal;
  vertical-align: initial;
}

#tarteaucitronRoot .tarteaucitronH1 {
  @apply p-6 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight;
}

#tarteaucitronRoot .tarteaucitronH2 {
  @apply inline-block text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight;
}

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0;
}

/***
* Control panel
*/
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647;
}

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  height: auto;
  overflow: auto;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  @apply m-6;
  background: theme("colors.white");
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px;
  z-index: 1;
}

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative;
}

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  display: inline-block;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
}

#tarteaucitron
  #tarteaucitronServices
  .tarteaucitronMainLine
  .tarteaucitronName
  a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500;
}

#tarteaucitron
  #tarteaucitronServices
  .tarteaucitronMainLine
  .tarteaucitronName
  a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important;
}

#tarteaucitron
  #tarteaucitronServices
  .tarteaucitronMainLine
  .tarteaucitronName
  a {
  font-size: 22px;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0;
}

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  display: none;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647;
}

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  margin: 0;
  overflow: hidden;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: theme("colors.white");
  margin-bottom: 0;
  margin-top: 21px;
  position: relative;
}

#tarteaucitron
  #tarteaucitronServices
  .tarteaucitronMainLine
  .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px;
}

#tarteaucitron
  #tarteaucitronServices
  .tarteaucitronMainLine
  .tarteaucitronName
  button {
  color: #fff;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 1.5rem;
  text-align: left;
  width: 50%;
}

#tarteaucitron
  #tarteaucitronServices
  .tarteaucitronLine
  .tarteaucitronName
  a:hover {
  text-decoration: underline;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right;
}

/***
* Big alert
*/
.tarteaucitronAlertBigTop {
  top: 0;
}

.tarteaucitronAlertBigBottom {
  bottom: 0;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
  @apply bg-white m-4 p-4 max-w-sm rounded-lg shadow-lg ring-1 ring-black ring-opacity-5;
  display: none;
  left: 0;
  position: fixed;
  box-sizing: content-box;
  z-index: 2147483645;
  text-align: center;
}

#tarteaucitronDisclaimerAlert {
  @apply block mb-4;
}

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  text-decoration: underline;
  font-size: 0.8em;
}

#tarteaucitron #tarteaucitronPrivacyUrlDialog {
  text-decoration: underline;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
#tarteaucitron #tarteaucitronClosePanel,
.tarteaucitronCTAButton,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  @apply rounded mb-2;
  display: inline-block;
  background: theme("colors.green.600");
  color: theme("colors.white");
  padding: 5px 10px;
}

#tarteaucitronRoot .tarteaucitronDeny {
  background: theme("colors.red.600");
}

#tarteaucitronRoot #tarteaucitronCloseAlert {
  background: theme("colors.orange.400");
}

#tarteaucitron #tarteaucitronClosePanel {
  background: theme("colors.gray.900");
  padding: 5px;
}

#tarteaucitronPercentage {
  background: #0a0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644;
}

/***
* Icon
*/
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0;
}
.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0;
}
.tarteaucitronIconTopRight {
  top: 0;
  right: 0;
}
.tarteaucitronIconTopLeft {
  top: 0;
  left: 0;
}

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646;
}
#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}
#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px;
}

#tarteaucitronRoot .tarteaucitronCross::before {
  content: "\2717";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: "\2713";
  display: inline-block;
  color: white;
}

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: "\271b";
  display: inline-block;
}

/***
* Small alert
*/

.tarteaucitronAlertSmallTop,
.tarteaucitronAlertSmallBottom {
  bottom: 0;
}

#tarteaucitronAlertSmall {
  background: theme("colors.gray.600");
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646;
}

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
}

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05);
}

#tarteaucitronAlertSmall
  #tarteaucitronManager
  #tarteaucitronDot
  #tarteaucitronDotGreen,
#tarteaucitronAlertSmall
  #tarteaucitronManager
  #tarteaucitronDot
  #tarteaucitronDotYellow,
#tarteaucitronAlertSmall
  #tarteaucitronManager
  #tarteaucitronDot
  #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%;
}

#tarteaucitronAlertSmall
  #tarteaucitronManager
  #tarteaucitronDot
  #tarteaucitronDotGreen {
  background-color: #1b870b;
}

#tarteaucitronAlertSmall
  #tarteaucitronManager
  #tarteaucitronDot
  #tarteaucitronDotYellow {
  background-color: #fbda26;
}

#tarteaucitronAlertSmall
  #tarteaucitronManager
  #tarteaucitronDot
  #tarteaucitronDotRed {
  background-color: #9c1a1a;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top;
}

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3);
}

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList {
  background: #fff;
  color: theme("colors.gray.600");
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  strong {
  color: theme("colors.gray.600");
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesTitle {
  background: theme("colors.gray.600");
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesTitle
  strong {
  color: #fff;
  font-size: 16px;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2);
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  a {
  color: theme("colors.gray.600");
  text-decoration: none;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  .tarteaucitronCookiesListLeft
  a
  strong {
  color: darkred;
}

#tarteaucitronAlertSmall
  #tarteaucitronCookiesListContainer
  #tarteaucitronCookiesList
  .tarteaucitronCookiesListMain
  .tarteaucitronCookiesListRight {
  color: theme("colors.gray.600");
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%;
}

/***
* Fallback activate link
*/
.tac_activate {
  background: theme("colors.gray.600");
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%;
}

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.tac_activate .tac_float strong {
  color: #fff;
}

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1b870b;
  display: inline-block;
}

/***
* CSS for services
*/
ins.ferank-publicite,
ins.adsbygoogle {
  text-decoration: none;
}

div.amazon_product {
  height: 240px;
  width: 120px;
}
.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important;
}
.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important;
}
.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important;
}
.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important;
}
.tarteaucitronLine .tarteaucitronAllow,
.tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4;
}
#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1;
}

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  max-width: none !important;
  padding: 1rem 1.5rem !important;
  margin: 0 0 1.5rem 0 !important;
}

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px;
}

.tarteaucitronBorder > ul > li:nth-child(odd) {
  @apply bg-gray-100;
}

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important;
}

#tarteaucitronCloseCross {
  position: absolute;
  color: #ffff;
  font-size: 1.8rem;
  cursor: pointer;
  top: 10px;
  right: 26px;
}

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block;
}

.tarteaucitron-display-block {
  display: block;
}

.tarteaucitron-display-none {
  display: none;
}
