/*
 * Bespoke (serif)
 */
@font-face {
  font-family: "Bespoke";
  src: url("../fonts/Bespoke/bespokeserif-medium.woff2") format("woff2"),
    url("../fonts/Bespoke/bespokeserif-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*
 * Gilroy (sans)
 */
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-medium-webfont.woff2") format("woff2"),
    url("../fonts/Gilroy/gilroy-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-semibold-webfont.woff2") format("woff2"),
    url("../fonts/Gilroy/gilroy-semibold-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy/gilroy-extrabold-webfont.woff2") format("woff2"),
    url("../fonts/Gilroy/gilroy-extrabold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
